.slide-pane {
  margin-top: 72px;
}

/* .slide-pane__content {
  max-height: calc(100vh - 110px);
} */

.slide-pane .slide-pane__header {
  border: none;
  background-color: #F3F4F6;
  color: #374151;
}

.slide-pane .slide-pane__title-wrapper {
  margin-left: 16px;
}

.slide-pane .slide-pane__title {
  font-size: 1rem;
  font-weight: 700;
}

.slide-pane .slide-pane__close {
  width: 52.3px;
  display: flex;
  justify-content: center;
  transition: all 200ms;
  border-radius: 0.5rem;
}

.slide-pane .slide-pane__close:hover {
  background-color: #D1D5DB;
}

.slide-pane-dark .slide-pane__header {
  background-color: #1F2937;
  color: #E5E7EB;
}

.slide-pane-dark .slide-pane__close:hover {
  background-color: #111827;
  border-radius: 0.5rem;
}